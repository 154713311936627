'use client';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { formatDateTime } from '../utils/formatDateTime';

const ONE_HOUR_SECONDS = 3600;

type RelativeTimeReturn = [
  string,
  { isNow?: boolean; isSoon?: boolean; isPast?: boolean; startEnd?: string },
];
type RelativeTimeOptions = {
  forceRelative?: boolean;
  endTime?: string;
};

export const useRelativeTime = (
  time?: string,
  options?: RelativeTimeOptions,
): RelativeTimeReturn => {
  const [now, setNow] = useState<DateTime>(DateTime.utc());
  const date = DateTime.fromISO(time!);
  const endDate = options?.endTime ? DateTime.fromISO(options.endTime) : undefined;
  const nowSeconds = now.toSeconds();
  const dateSeconds = date.toSeconds();

  const minFormat = date.toFormat('mm') === '00' ? '' : ':mm';
  const minFormatEnd = endDate ? (endDate.toFormat('mm') === '00' ? '' : ':mm') : undefined;

  const startEnd = endDate
    ? `${date.toFormat(`EEE, LLL d 'from' h${minFormat}a`)} until ${endDate.toFormat(
        `h${minFormatEnd}a ZZZZ`,
      )}`
    : undefined;

  useEffect(() => {
    const timerID = setInterval(() => setNow(DateTime.utc()), 5000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });
  if (!date || !date.isValid || date === undefined) {
    return ['Invalid Date', {}];
  }

  // check if it's past
  const isPast = nowSeconds > dateSeconds + ONE_HOUR_SECONDS;
  if (isPast) {
    return [formatDateTime(date), { isPast, startEnd }];
  }

  // check if it's now
  const isNow = nowSeconds >= dateSeconds && nowSeconds < dateSeconds + ONE_HOUR_SECONDS;
  if (isNow) {
    return ['Happening now', { isNow, startEnd }];
  }

  // check if it's within 1 hour
  const isSoon = nowSeconds >= dateSeconds - ONE_HOUR_SECONDS;
  if (isSoon) {
    return [date.toRelative() ?? '', { isSoon, startEnd }];
  }

  if (startEnd) {
    return [startEnd, {}];
  }

  return [options?.forceRelative ? date.toRelative() ?? '' : formatDateTime(date), {}];
};
